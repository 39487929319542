import { Box } from "@mui/system";
import React from "react";
import ChooseUs from "../components/ChooseUs";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import OurStory from "../components/OurStory";
import PriceList from "../components/PriceList";
import Testimonial from "../components/Testimonial";

const HomePage = () => {
  return (
    <Box>
      <Hero />
      <ChooseUs />
      <PriceList />
      <OurStory />
      <Testimonial />
      <Contact />
      <Footer />
    </Box>
  );
};

export default HomePage;
