import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

const PriceList = () => {
  const prices = [
    { job: "Oil Change", plus_parts: "-", price: "$" },
    { job: "Tire Rotation", plus_parts: "-", price: "$" },
    { job: "D.O.T. Inspection", plus_parts: "-", price: "$" },
    { job: "Diagnostic", plus_parts: "-", price: "$" },
    { job: "Radiator Flush", plus_parts: "-", price: "$" },
    {
      job: "Diesel Oil Chang 4gal",
      plus_parts: "-",
      price: "$",
    },
    { job: "Transmission Service", plus_parts: "-", price: "$" },
    { job: "Tune Ups", plus_parts: "-", price: "$" },
    { job: "A/C Service", plus_parts: "-", price: "$" },
    { job: "Rear End Service", plus_parts: "-", price: "$" },
    {
      job: "Induction Service Gas/Diesel",
      plus_parts: "-",
      price: "$",
    },
    { job: "Break Job", plus_parts: "-", price: "$" },
  ];

  return (
    <Box sx={{ my: 5 }}>
      <Container maxWidth="lg">
        <Container
          maxWidth="xl"
          sx={{ justifyContent: "center", display: "flex" }}
        >
          <Typography variant="h4">A Few of Our Services</Typography>
        </Container>
        <TableContainer sx={{ py: 5 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Job</TableCell>
                <TableCell align="center">Parts Required</TableCell>
                <TableCell align="center">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prices.map((price) => (
                <TableRow key={price.job} hover={true}>
                  <TableCell>{price.job}</TableCell>
                  <TableCell align="center">{price.plus_parts}</TableCell>
                  <TableCell align="center">{price.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default PriceList;
