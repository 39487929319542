import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import Fish from "../assets/icons/fish.png";
import Wrench from "../assets/icons/wrench.png";
import Time from "../assets/icons/time.png";
import Calendar from "../assets/icons/calendar.png";
import Checkmark from "../assets/icons/checkmark.png";

const ChooseUs = () => {
  const slides = [
    {
      title: "We put God first.",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur beatae alias maiores ducimus delectus deleniti facere amet neque eius quos.",
      icon: Fish,
    },
    {
      title: "We are ASE certified and insured.",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur beatae alias maiores ducimus delectus deleniti facere amet neque eius quos.",
      icon: Wrench,
    },
    {
      title: "We work around your schedule.",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur beatae alias maiores ducimus delectus deleniti facere amet neque eius quos.",
      icon: Time,
    },
    {
      title: "We have over 30 years of experience.",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur beatae alias maiores ducimus delectus deleniti facere amet neque eius quos.",
      icon: Calendar,
    },
    {
      title: "We warranty all of our work.",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur beatae alias maiores ducimus delectus deleniti facere amet neque eius quos.",
      icon: Checkmark,
    },
  ];

  return (
    <Box className="choose-us">
      <Typography
        variant="h3"
        sx={{ justifyContent: "center", display: "flex", pt: 5 }}
      >
        Why Choose Us?
      </Typography>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
        spaceBetween={5}
        slidesPerView={1}
      >
        {slides.map((item) => (
          <SwiperSlide key={item.title}>
            <Box my={5}>
              <img alt="icon" src={item.icon} />
              <Typography variant="h2" sx={{ mb: 2 }}>
                {item.title}
              </Typography>
              <Container maxWidth="xl">
                <Typography variant="h5">{item.content}</Typography>
              </Container>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default ChooseUs;
