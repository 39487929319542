import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import "../App.css";

const Hero = () => {
  return (
    <Box>
      <Paper className="section">
        <div className="overlay">
          {" "}
          <Grid
            className="content"
            container
            alignItems="center"
            sx={{ px: 5 }}
          >
            <Grid item>
              <Typography component="h1" variant="h2" color="white">
                A trusted mechanic that comes to you.
              </Typography>
              <Typography
                component="h2"
                variant="h5"
                color="white"
                sx={{ my: 1 }}
              >
                Contact us today to find out how we can serve you!
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                href="#contact"
                sx={{ mx: 0, my: 2, p: 2, fontSize: 20 }}
              >
                CONTACT
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Box>
  );
};

export default Hero;
