import { AppBar, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const NavBar = () => {
  return (
    <Box>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h4" sx={{ flexGrow: 1 }}>
            MOBILE MECHANIC
          </Typography>
          <Typography
            variant="h4"
            color="secondary"
            display={{ xs: "none", md: "flex" }}
          >
            555-555-5555
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
