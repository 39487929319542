import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "../App.css";

const Contact = () => {
  return (
    <Box id="contact" className="contact-box">
      <Grid
        className="content"
        sx={{ justifyContent: "center", display: "flex", py: 6 }}
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="h2"
            color="white"
            sx={{ pb: 2, textAlign: "center" }}
          >
            Contact Us Today
          </Typography>
          <Typography
            sx={{ pb: 2 }}
            variant="h3"
            color="secondary"
            textAlign="center"
          >
            555-555-5555
          </Typography>
          <Grid item sx={{ justifyContent: "center", display: "flex" }}>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <Button
                sx={{ p: 2, fontSize: 25 }}
                variant="contained"
                color="secondary"
              >
                CALL
              </Button>
            </Box>
            <Button
              sx={{ p: 2, fontSize: 25 }}
              variant="outlined"
              color="secondary"
            >
              EMAIL
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
