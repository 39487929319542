import { Box, createTheme, ThemeProvider } from "@mui/material";
import "./App.css";
import NavBar from "./components/NavBar";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";

const theme = createTheme({
  typography: {
    fontFamily: "Open Sans",
  },
  palette: {
    primary: {
      main: "#0e2e9b",
    },
    secondary: {
      main: "#f94d40",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
        </Routes>
      </Box>
    </ThemeProvider>
  );
}

export default App;
