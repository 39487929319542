import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Parallax } from "react-parallax";

const OurStory = () => {
  return (
    <Parallax
      blur={2}
      bgImage={require("../assets/images/wrenches.jpg")}
      bgImageAlt="ourstory"
      strength={300}
    >
      <Box height={{ xs: "100%", md: "50vh" }} sx={{ m: 3 }}>
        <Grid container alignItems="center" className="content">
          <Grid item>
            <Typography
              variant="h2"
              color="white"
              textAlign="center"
              sx={{ pb: 2 }}
            >
              Our Story
            </Typography>
            <Typography variant="h4" color="white" textAlign="center">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic
              dolor officia adipisci, autem magni ab ut, consectetur unde
              officiis assumenda veritatis suscipit! Commodi nulla ad expedita
              fugit excepturi aliquid laborum maiores voluptate quisquam
              repudiandae. Quibusdam dignissimos voluptatibus nobis incidunt aut
              maxime, amet excepturi sit natus, tempore deserunt, nulla eveniet
              iusto!
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Parallax>
  );
};

export default OurStory;
