import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Testimonial = () => {
  const testimony = [
    {
      name: "Ben Craig",
      photo: "https://source.unsplash.com/iEEBWgY_6lA",
      description:
        '"I have been using Mobile Mechanic for years. They are professional and trustworthy. I highly recommend them!"',
    },
    {
      name: "Justin Adams",
      photo: "https://source.unsplash.com/2EGNqazbAMk",
      description:
        '"If you are looking for a trustworthy mechanic, these guys are the ones to call. Great price and fast service."',
    },
    {
      name: "Liz Thomas",
      photo: "https://source.unsplash.com/MxkYQ39KFmE",
      description:
        '"I will never use another mechanic. Mobile mechanic iscompetent and answers all my questions. Great company!"',
    },
  ];

  return (
    <Box sx={{ py: 5 }}>
      <Container
        maxWidth="xl"
        sx={{
          textAlign: "center",
        }}
      >
        <Typography variant="h4">Some of Our Customers</Typography>
      </Container>
      <Grid container justifyContent="center">
        {testimony.map((item) => (
          <Grid item>
            <Box
              sx={{
                my: 3,
              }}
            >
              <Card
                sx={{
                  maxWidth: 345,
                  boxShadow: 20,
                  mx: 3,
                }}
              >
                <CardMedia component="img" height="350" image={item.photo} />
                <CardContent sx={{ height: "150px" }}>
                  <Typography variant="h5" sx={{ textAlign: "center", py: 1 }}>
                    {item.name}
                  </Typography>
                  <Typography variant="body1">{item.description}</Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Testimonial;
