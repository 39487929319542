import { Box } from "@mui/system";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import Link from "@mui/material/Link";
import { Grid, IconButton, Typography } from "@mui/material";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <Box
      sx={{ display: "flex" }}
      height={{ xs: "100%", md: "30vh" }}
      backgroundColor="#E8E8E8"
      width="100%"
    >
      <Box width="100%" sx={{ my: 5 }}>
        <Grid
          sx={{
            justifyContent: "center",
            display: "flex",
            textAlign: "center",
          }}
          alignItems="center"
          height="100%"
        >
          <Grid item>
            <Typography variant="h6" color="inherit">
              Serving Metro Atlanta
            </Typography>
            <Typography variant="h6" color="inherit">
              Monday to Friday 7am - 5pm
            </Typography>
            <Typography variant="h6" color="inherit">
              We are Hiring!
            </Typography>
            <IconButton>
              <FacebookIcon fontSize="large" />
            </IconButton>
            <Typography variant="body2">
              Copywrite @ {year} Mobile Mechanic
            </Typography>
            <Link variant="body2" color="inherit" underline="hover">
              by Wes Garvin
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
